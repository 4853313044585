var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "head" },
        [
          _c(
            "MatchHeadTeam",
            _vm._b({}, "MatchHeadTeam", _vm.normalizedTeams[0], false)
          ),
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "icons" },
              [
                _c("VodStatusIndicator", {
                  attrs: { status: _vm.replay_cropper_status }
                })
              ],
              1
            ),
            _c("div", { staticClass: "score" }, [
              _c(
                "span",
                {
                  staticClass: "score__num",
                  class: "score__num--" + (_vm.teams[0].win ? "win" : "loss")
                },
                [_vm._v(" " + _vm._s(_vm.teams[0].rounds_won || "-") + " ")]
              ),
              _c("span", { staticClass: "score__separatore" }, [_vm._v(":")]),
              _c(
                "span",
                {
                  staticClass: "score__num",
                  class: "score__num--" + (_vm.teams[1].win ? "win" : "loss")
                },
                [_vm._v(" " + _vm._s(_vm.teams[1].rounds_won || "-") + " ")]
              )
            ]),
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm.created
                  ? _c(
                      "time",
                      {
                        staticClass: "column",
                        attrs: { datetime: _vm._f("isoString")(_vm.created) }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("localDate")(_vm.created)))
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("localTime")(_vm.created)))
                        ])
                      ]
                    )
                  : _vm._e(),
                _c("MapCell", { attrs: { id: _vm.map, variant: "row" } })
              ],
              1
            )
          ]),
          _c(
            "MatchHeadTeam",
            _vm._b({}, "MatchHeadTeam", _vm.normalizedTeams[1], false)
          )
        ],
        1
      ),
      _c("MatchRoundSelector", {
        staticClass: "justify-content-center mb-5",
        attrs: {
          rounds: _vm.rounds,
          teams: _vm.normalizedTeams,
          "team-links": false,
          scores: _vm.scores
        },
        scopedSlots: _vm._u([
          {
            key: "round",
            fn: function(ref) {
              var round = ref.round
              return [
                _c(
                  "MatchRoundSelectorItem",
                  _vm._b(
                    {
                      key: round.round_number,
                      attrs: {
                        tag: "router-link",
                        selected: round.round_number === _vm.roundNum,
                        to: { query: { round: round.round_number } }
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.track_round(round.number)
                        }
                      }
                    },
                    "MatchRoundSelectorItem",
                    round,
                    false
                  )
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }