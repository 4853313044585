<template>
  <div class="container">
    <div class="head">
      <MatchHeadTeam v-bind="normalizedTeams[0]" />
      <div class="column">
        <div class="icons">
          <VodStatusIndicator :status="replay_cropper_status" />
        </div>
        <div class="score">
          <span class="score__num" :class="`score__num--${teams[0].win ? 'win' : 'loss'}`">
            {{ teams[0].rounds_won || '-' }}
          </span>
          <span class="score__separatore">:</span>
          <span class="score__num" :class="`score__num--${teams[1].win ? 'win' : 'loss'}`">
            {{ teams[1].rounds_won || '-' }}
          </span>
        </div>
        <div class="column">
          <time v-if="created" :datetime="created | isoString" class="column">
            <span>{{ created | localDate }}</span>
            <span>{{ created | localTime }}</span>
          </time>
          <MapCell :id="map" variant="row" />
        </div>
      </div>
      <MatchHeadTeam v-bind="normalizedTeams[1]" />
    </div>

    <MatchRoundSelector
      :rounds="rounds"
      :teams="normalizedTeams"
      :team-links="false"
      :scores="scores"
      class="justify-content-center mb-5"
    >
      <template #round="{ round }">
        <MatchRoundSelectorItem
          :key="round.round_number"
          v-bind="round"
          tag="router-link"
          :selected="round.round_number === roundNum"
          :to="{ query: { round: round.round_number } }"
          @click.native="track_round(round.number)"
        />
      </template>
    </MatchRoundSelector>
  </div>
</template>

<script>
import px from 'vue-types'

import isoString from '../../filters/isoString.js'
import localDate from '../../filters/localDate.js'
import localTime from '../../filters/localTime.js'
import mixpanel from '../../mixpanel.js'
import calcTeamRole from '../../utils/calcTeamRole.js'
import { pxGrid, pxNullable, pxVodStatus } from '../Map/types.js'
import MatchHeadTeam from '../match/head/Team.vue'
import MatchRoundSelector from '../match/MatchRoundSelector.vue'
import MatchRoundSelectorItem from '../match/roundSelector/MatchRoundSelectorItem.vue'
import MapCell from '../Table/cells/MapCell.vue'
import VodStatusIndicator from '../UI/VodStatusIndicator.vue'

export default {
  name: 'ScrimHead',
  components: { MatchRoundSelectorItem, MatchRoundSelector, VodStatusIndicator, MapCell, MatchHeadTeam },
  props: {
    id: px.string,
    created: px.string,
    map: px.string,
    roundNum: pxNullable(px.number).def(null),
    rounds_played: px.number,
    teams: px.arrayOf(
      px.shape({
        id: pxNullable(px.string),
        alias: px.string,
        team_side: pxGrid(),
        agent_comp: px.arrayOf(px.string),
        rounds_won: px.number,
        win: px.bool,
      }).loose
    ),
    replay_cropper_status: pxVodStatus(),
    roundScores: pxNullable(px.arrayOf(px.object)),
  },
  computed: {
    normalizedTeams() {
      return this.teams?.map(team =>
        Object.freeze({
          image: team.logo_url,
          id: team.id,
          winner: team.win,
          name: team.name,
          score: team.rounds_won,
          breakdown: {
            first: {
              score: team.rounds_first_half_won,
              role: calcTeamRole(team.team_side, 0),
            },
            second: {
              score: team.rounds_second_half_won,
              role: calcTeamRole(team.team_side, 12),
            },
            overtime: {
              score: team.rounds_overtime_won,
            },
          },
        })
      )
    },
    rounds() {
      return Array.from({ length: this.rounds_played }, (_, idx) => ({
        round_number: idx,
        number: idx + 1,
        loser: {
          grid: this.teams[1].team_side,
          role: calcTeamRole(this.teams[1].team_side, idx),
        },
        winner: {
          grid: this.teams[0].team_side,
          role: calcTeamRole(this.teams[0].team_side, idx),
        },
      }))
    },
    scores() {
      return this.teams.map(({ rounds_won }) => rounds_won)
    },
  },
  filters: {
    isoString,
    localDate,
    localTime,
  },
  methods: {
    track_round(round) {
      mixpanel.track_scrim_round(this, round)
    },
  },
}
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.score {
  font-size: 1.5rem;
  margin: 1em;

  &__num {
    &--win {
      color: $highlight;
    }
  }
}
</style>
