<template>
  <ApiLoadingController :fetch="fetch" :params="scrimId">
    <template #default="{ data }">
      <div class="title-container">
        <PageTitle>Scrim {{ data.name }}</PageTitle>
        <router-link :to="{ path: 'edit', query: $route.query }" class="btn btn-outline-primary btn-edit-scrim">
          <b-icon-pencil></b-icon-pencil> Edit
        </router-link>
      </div>
      <ScrimHead v-bind="data" :round-num="roundNum" />

      <AppTabs>
        <template #tabs-start>
          <BNavItem :to="{ path: 'overview', query: $route.query }" exact exact-active-class="active">
            Overview
          </BNavItem>
          <BNavItem :to="{ path: 'economy', query: $route.query }" exact exact-active-class="active">
            Economy
          </BNavItem>
          <BNavItem :to="{ path: 'performance', query: $route.query }" exact exact-active-class="active">
            K/D/A
          </BNavItem>
          <!-- Temporary disabe notes until APIs are done -->
          <BNavItem v-if="false" :to="{ path: 'notes', query: $route.query }" exact exact-active-class="active">
            Notes
          </BNavItem>
          <BNavItem :to="{ path: 'map', query: $route.query }" exact exact-active-class="active"> 2D Map </BNavItem>
        </template>
      </AppTabs>

      <router-view name="content" />
    </template>
  </ApiLoadingController>
</template>

<script>
import { BNavItem, BIconPencil } from 'bootstrap-vue'
import px from 'vue-types'

import { getScrim, getScrimAdvancedOverview } from '@/api/scrims.js'
import { prepareScrimData } from '@/utils/prepareScrimData'

import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import PageTitle from '../../components/generic/PageTitle.vue'
import AppTabs from '../../components/generic/Tabs.vue'
import ScrimHead from '../../components/scrim/ScrimHead.vue'

export default {
  name: 'ScrimPage',
  components: { AppTabs, BNavItem, ScrimHead, PageTitle, ApiLoadingController, BIconPencil },
  props: {
    scrimId: px.string.isRequired,
    roundNum: px.integer,
  },
  data: () => ({
    scrimData: {
      scrim: null,
    },
  }),
  provide() {
    return {
      scrimData: this.scrimData,
    }
  },
  methods: {
    async fetch(scrimId) {
      const scrim = await getScrim(scrimId)
      const advanced = await getScrimAdvancedOverview(scrimId)
      this.scrimData.scrim = prepareScrimData.call(this, scrim, advanced)
      return this.scrimData.scrim
    },
  },
}
</script>

<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.btn-edit-scrim {
  margin-left: 20px;
  margin-top: 0.4rem;
}
</style>
