var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { fetch: _vm.fetch, params: _vm.scrimId },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _c(
              "div",
              { staticClass: "title-container" },
              [
                _c("PageTitle", [_vm._v("Scrim " + _vm._s(data.name))]),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-outline-primary btn-edit-scrim",
                    attrs: { to: { path: "edit", query: _vm.$route.query } }
                  },
                  [_c("b-icon-pencil"), _vm._v(" Edit ")],
                  1
                )
              ],
              1
            ),
            _c(
              "ScrimHead",
              _vm._b(
                { attrs: { "round-num": _vm.roundNum } },
                "ScrimHead",
                data,
                false
              )
            ),
            _c("AppTabs", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "tabs-start",
                    fn: function() {
                      return [
                        _c(
                          "BNavItem",
                          {
                            attrs: {
                              to: { path: "overview", query: _vm.$route.query },
                              exact: "",
                              "exact-active-class": "active"
                            }
                          },
                          [_vm._v(" Overview ")]
                        ),
                        _c(
                          "BNavItem",
                          {
                            attrs: {
                              to: { path: "economy", query: _vm.$route.query },
                              exact: "",
                              "exact-active-class": "active"
                            }
                          },
                          [_vm._v(" Economy ")]
                        ),
                        _c(
                          "BNavItem",
                          {
                            attrs: {
                              to: {
                                path: "performance",
                                query: _vm.$route.query
                              },
                              exact: "",
                              "exact-active-class": "active"
                            }
                          },
                          [_vm._v(" K/D/A ")]
                        ),
                        false
                          ? _c(
                              "BNavItem",
                              {
                                attrs: {
                                  to: {
                                    path: "notes",
                                    query: _vm.$route.query
                                  },
                                  exact: "",
                                  "exact-active-class": "active"
                                }
                              },
                              [_vm._v(" Notes ")]
                            )
                          : _vm._e(),
                        _c(
                          "BNavItem",
                          {
                            attrs: {
                              to: { path: "map", query: _vm.$route.query },
                              exact: "",
                              "exact-active-class": "active"
                            }
                          },
                          [_vm._v(" 2D Map ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            }),
            _c("router-view", { attrs: { name: "content" } })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }