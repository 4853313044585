/**
 * @type {Record<API_TEAM_GRID, Record<boolean, API_TEAM_ROLE>>}
 */
const MAP = {
  Red: {
    true: 'atk',
    false: 'def',
  },
  Blue: {
    true: 'def',
    false: 'atk',
  },
}

/**
 * Computes the team's role for a give round
 * @param {API_TEAM_GRID} grid
 * @param {number} round
 * @return {API_TEAM_ROLE}
 */
export default function calcTeamRole(grid, round) {
  const same = Boolean(round < 12 || (round > 23 && (round + 1) % 2))
  return MAP[grid][same]
}
